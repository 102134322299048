module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Listener","short_name":"Listener","start_url":"/","background_color":"##73c6d5","theme_color":"##73c6d5","display":"minimal-ui","icon":"src/images/listener.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3342ad7016bee839344d780dada4809f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://listener.app"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T4B6QTN","includeInDevelopment":false,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pagePath,\n          }\n        }"},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-T4B6QTN","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Zilla Slab","file":"https://fonts.googleapis.com/css?family=Zilla+Slab:400,500,600,700&display=swap"},{"name":"Nunito","file":"https://fonts.googleapis.com/css?family=Nunito:200,400,600,700,800&display=swap"},{"name":"Open Sans","file":"https://fonts.googleapis.com/css?family=Open+Sans:400,500,600,700&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css?family=Roboto:400,500,600,700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-amplitude/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"c180c5f09a24fb7999ceaed2b34a6951","enableOnDevMode":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
